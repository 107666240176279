import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Banner from "../components/banner"
import Content from "../components/content"
import Sections from "../components/sections"

const AboutUsPage = ({ data, pageContext }) => {
  const {
    breadcrumb: { crumbs },
  } = pageContext;

  const title = data.markdownRemark.frontmatter.title;
  const caption = data.markdownRemark.frontmatter.caption;
  const bannerImage = data.markdownRemark.frontmatter.banner.banner_image.childImageSharp.fluid;
  const bannerCaption = data.markdownRemark.frontmatter.banner.banner_caption;
  const bannerCaptionBold = data.markdownRemark.frontmatter.banner.banner_caption_bold;
  const sectionsContent = data.markdownRemark.frontmatter.sections;
  const body = data.markdownRemark.html;

  return (
    <Layout progressEnabled={true}>
      <SEO title={title} />
      <Banner image={bannerImage} bannerCaption={bannerCaption} bannerCaptionBold={bannerCaptionBold} />
      <Content title={title} crumbs={crumbs} caption={caption} body={body} />
      <Sections sectionsContent={sectionsContent} horizontalLayout={true} />
    </Layout>
  )
}

export const query = graphql`
query {
  markdownRemark(frontmatter: { template_key: { eq: "about-us" } }) {
    frontmatter {
      title
      caption
      banner {
        banner_caption
        banner_caption_bold
        banner_image {
          childImageSharp {
            fluid(maxWidth: 900, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      sections {
        subtitle
        title
        caption
        button_text
        url
        external_link
        image {
          childImageSharp {
            fluid(maxWidth: 900, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    html
  }
}
`

export default AboutUsPage
